<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-layout row justify-space-between align-center>
          <v-flex xs6>
            <span class="heading">Pending Cases</span>
          </v-flex>
        
            <!-- <v-flex xs12 sm6 md6 lg5 pl-md-2 pl-lg-2 pl-sm-2>
              
              <v-text-field
                outlined
                hide-details
                dense
                placeholder="Type To Search"
                style="font-family: sofiaProRegular;"
                v-model="keyword"
              >
              </v-text-field>
            </v-flex> -->
            </v-layout>

          <v-flex xs12 v-if="items && items.length > 0">
            
            <v-layout wrap justify-start pt-4>
              <v-flex xs12>
                <v-card>
                  <div style="overflow-y: auto;">
                    <v-simple-table>
                      <template v-slot:default>
                    <thead>
                      <tr>
                        <th class=" tablehead">Reported Date</th>
                        <th class=" tablehead">Description</th>
                        <th class=" tablehead">situation</th>
                        <th class=" tablehead">snake</th>
                        <th class=" tablehead">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <td style="white-space: nowrap;" class="tableitems">{{ formatDate(item.reportedAt) }}</td>
                        <td style="white-space: nowrap;cursor:pointer" @click="openModal(item.description)" class="tableitems">{{ item.description.slice(0,50) }}...</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.situation }}</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.snake }}</td>
                        <td cursor:pointer>
                          
                          <v-icon
                          small
                          color="success"
                          class="ml-4"
                          @click.stop="openviewdetails(item)"
                        >
                          mdi-eye
                        </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                </div>
              </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="items && items.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              circle
              color="#FF6907"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout
          wrap
          justify-center
          align-center
         
        >
          <v-flex xs12 class="text-center">
            <span class="nodata">No Data Found !</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Delete Rescuer</v-card-title>
        <v-card-text>
          Are you sure you want to delete this rescuer?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteUser()"
            class="itemValue"
          >
            <span style="color: #fff">Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- description -->
    <v-dialog v-model="isModalOpen" max-width="500px">
      <v-card>
        <v-card-title class="tablehead">Full Description</v-card-title>
        <v-card-text class="label" style="color: black;">{{ fullDescription }}</v-card-text>
        <v-card-actions>
       
          <v-btn color="#FF1313" text @click="closeModal">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
        <script>
import axios from "axios";
export default {
  data() {
    return {
       isModalOpen: false,
      fullDescription: '',
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      count: 20,
      keyword: "",
      currentPage: 1,
      Pagelength: 0,
      userlist: [],
      districts: [
        "Palakkad",
        "Alappuzha",
        "Malappuram",
        "Kannur",
        "Wayanadu",
        "Kozhikode",
        "Thrissur",
        "Thiruvananthapuram",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Kollam",
        "Pathanamthitta",
        "Kasaragod",
      ],
      deleteDialog: false,
      items: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
    },
  },
  methods: {
    openModal(description) {
      this.fullDescription = description;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openviewdetails(item) {
      this.$router.push({
        path: "/viewpendingoperations",
        query: {
          id: item._id,
        },
      });
    },
    rejectDialog(item) {
      this.deleteDialog = true;
      this.deleteid = item;
    },
    openeditdetails(item) {
      this.$router.push({
        path: "/newrescuerslistedit",
        query: {
          id: item.user._id,
        },
      });
    },
    deleteUser() {
      this.appLoading = true;
      axios({
        method: "DELETE",
        url: "/user/delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.deleteid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Deleted Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.deleteDialog = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.deleteDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/operation/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          status: "Pending",
          keyword:this.keyword,
          count :this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              var temp = [];
              for (var i = 0; i < this.items.length; i++) {
                if (this.items[i].reportId != null)
                  var it = this.items[i].reportId;
                it._id = this.items[i]._id;
                it.reportedAt = this.items[i].reportedAt;
                if (this.items[i].reportId != null) {
                  if (this.items[i].reportId.snake)
                    it.snake = this.items[i].reportId.snake.name;
                }
                temp.push(it);
              }
              this.items = temp;
              this.Pagelength = response.data.pages;
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    handlesubmit() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/snake/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          name: this.commonname,
          scientificName: this.scientificname,
          states: this.state,
          rname: this.reginonalname,
          distribution: this.distribution,
          characteristics: this.scalation,
          description: this.description,
          venomType: this.venomtype,
          family: this.family,
          occurence: this.occurence,
          wlpa: this.wlpa,
          iucn: this.iucn,
          shortdes: this.shortdescription,
          othernames: this.othername,
          genus: this.genus,
          experts: this.experts,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.commonname = "";
              this.scientificname = "";
              this.state = "";
              this.reginonalname = "";
              this.distribution = "";
              this.scalation = "";
              this.description = "";
              this.venomtype = "";
              this.family = "";
              this.occurence = "";
              this.wlpa = "";
              this.iucn = "";
              this.shortdescription = "";
              this.othername = "";
              this.genus = "";
              this.experts = "";
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    formatDate(date) {
    if (!date) return '-';
    const [year, month, day] = date.slice(0, 10).split('-');
    return `${day}-${month}-${year}`;
  }
  },
};
</script>
        <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>